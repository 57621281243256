import domReady from '@wordpress/dom-ready'
import ScrollReveal from 'scrollreveal'

function animationOnReveal () {
	const isHome = document.body.classList.contains('home')
	console.log({isHome})
	// if (!isHome) {
	// 	return false
	// }
	const nodeList = document.querySelectorAll('.from-bottom')
	const crossBoxLeft = document.querySelectorAll('.is-style-product-category-left')
	const crossBoxRight = document.querySelectorAll('.is-style-product-category-right')

	const slideUp = {
		delay:    50,
		useDelay: 'once',
		reset:    false,
		mobile:   false,
		origin:   'bottom',
		distance: '250px',
		cleanup:  true
	}

	const slideLeft = {
		delay:    500,
		distance: '100%',
		origin:   'left',
		opacity:  1,
		useDelay: 'once',
		reset:    false,
		mobile:   false,
		cleanup:  true
	}

	const slideRight = {
		delay:    500,
		distance: '100%',
		origin:   'right',
		opacity:  1,
		useDelay: 'once',
		reset:    false,
		mobile:   false,
		cleanup:  true
	}

	ScrollReveal().reveal('.is-style-product-category-details', {
		distance: '0px'
	})

	ScrollReveal().reveal(crossBoxLeft, slideLeft)
	ScrollReveal().reveal(crossBoxRight, slideRight)
	ScrollReveal().reveal(nodeList, slideUp)
}

domReady(() => {
	animationOnReveal()
})


document.addEventListener("DOMContentLoaded", function() {
	var galleryLinks = document.querySelectorAll('.woocommerce-product-gallery__image a');
	galleryLinks.forEach(function(link) {
		link.setAttribute('href', '#');
	});
});



jQuery(document).ready(function($) {
    // Listen for the click event on add to cart buttons
    $('body').on('click', '.add_to_cart_button', function(e) {
        // Prevent default action if necessary
        // e.preventDefault();

        // Update cart quantity
        var cartQtyElement = $('.cart-info.cart-qty');
        var currentQty = parseInt(cartQtyElement.text(), 10);
        cartQtyElement.text(currentQty + 1);

        // Determine product price
        var productPrice;
        if ($(this).data('price')) {
            productPrice = parseFloat($(this).data('price'));
        } else {
            var productItem = $(this).closest('.product');
            var productPriceElement = productItem.find('.woocommerce-Price-amount');
            productPrice = parseFloat(productPriceElement.text().replace(/[^0-9.-]+/g, ""));
        }

        // Update cart total
        var cartTotalElement = $('.cart-info.cart-total .woocommerce-Price-amount.amount bdi');
        var currentTotal = parseFloat(cartTotalElement.text().replace(/[^0-9.-]+/g, ""));
        var newTotal = (currentTotal + productPrice).toFixed(2);

        // Ensure CHF is displayed before the total
        cartTotalElement.html('CHF ' + newTotal);
    });
});






