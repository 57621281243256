/* eslint-disable @wordpress/dependency-group */
import $ from 'jquery'

import './inc/responsive-menus'
import './inc/in-view'
// import './inc/mini-cart'

// $(window).on('load', function () {
// 	document.querySelectorAll('*').forEach((el) => {
// 		// console.log('element: ', el);
// 		if (el.offsetWidth > document.documentElement.offsetWidth) {
// 			console.log('OVERFLOW ----- Found the worst element ever: ', el)
// 		}
// 	})
// })
